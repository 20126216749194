<div class="flex z-1 fixed header bg-color align-items-center pr-3 py-2 border-black-alpha-10 border-bottom-1 h-3rem">
    <div class="flex h-4rem justify-content-center">
        <a class="flex align-items-center cursor-pointer logo" routerLink="/welcome">
            <img src="assets/images/logo/saraplus_logo_menu.png" alt="Sara plus" />
        </a>
    </div>
    <div class="flex header ml-5 justify-content-between">
        <app-page-title></app-page-title>
        <div>
            <app-profile></app-profile>
        </div>
    
    </div>
</div>
