

export const environment = {
    production: false,
    apiUrl: '#{apiUrl}#',
    signalRurl: '#{signalRurl}#',
    sourceName: 'SARAPLUS QA',
    tokenCredentials: '#{tokenCredentials}#',
    sessionDecryptKey: '#{sessionDecryptKey}#',
    ignoreEncryption: true,
    azureFDID: '6b3c6079-782d-4ffa-95e7-6c0f459b0083',
    sessionTimeout: '#{sessionTimeout}#',
    sessionIdle: '#{sessionIdle}#',
    appVersion: '#{buildVersion}#',
}