import { inject, Injectable } from '@angular/core'
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AppStateService } from '../store/state/app/app.state.service';



@Injectable({
    providedIn: 'root'
})
export class SignalRHubService {
    private hubConnection: signalR.HubConnection;
    private environmentService: EnvironmentService = inject(EnvironmentService);
    #appStateService = inject(AppStateService)
    #signalREventService = inject(SignalREventService)

    private hubUrl: string;
    constructor() {
        const sessionId = this.#appStateService.getSession()?.SessionID
        const headers: signalR.MessageHeaders = { 'X-SARAPlus-ID': this.environmentService.env.azureFDID, 'SessionId': sessionId }
        this.hubUrl = this.environmentService.getSignalrUrl('saraplus-hub');
        this.hubUrl += `?sessionId=${sessionId}`;
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.hubUrl,
                {
                    headers: headers,
                    withCredentials: true,
                    transport: signalR.HttpTransportType.WebSockets,
                }) // SignalR hub URL
            .withAutomaticReconnect()
            .build();

    }

    startConnection(): Observable<void> {
        return new Observable<void>((observer) => {
            this.hubConnection
                .start()
                .then(() => {
                    console.log('Connection established with SignalR hub');
                    observer.next();
                    observer.complete();
                    this.startListening()
                })
                .catch((error) => {
                    console.error('Error connecting to SignalR hub:', error);
                    observer.error(error);
                });
        });
    }

    startListening() {
        this.hubConnection.on('ReceiveMessage', (message: SignalRModel) => {
            console.log(JSON.stringify(message));
            if (typeof message == "object" && 'type' in message && 'data' in message) {
                try {
                    if (typeof message.data === 'string') {
                        message.data = JSON.parse(message.data as string);
                    }
                    this.#signalREventService.RaiseEvent(message);
                } catch (error) {
                    console.log('SignalR JSON parsing error', error, message);
                }

            }
        });

        this.hubConnection.on('SecureDataMessage', (message) => {
            console.log('SecureDataMessage', message);
            this.#signalREventService.RaiseEvent(message);
        });

        this.hubConnection.on('ReceiveEventB', (message) => {
            console.log('ReceiveEventB', message);
            this.#signalREventService.RaiseEvent(message);
        });
    }

    sendMessage(message: SignalRModel): void {
        this.hubConnection.invoke('SendMessage', message);
    }

    closeConnection(): Promise<void> {
        if (this.hubConnection) {
            return this.hubConnection.stop()
                .then(() => console.log('Connection stopped'))
                .catch(err => console.log('Error while stopping connection: ' + err));
        }
        return Promise.resolve();
    }
}

@Injectable({
    providedIn: 'root'
})
export class SignalREventService {
    private $eventSubscriptiob = new BehaviorSubject<SignalRModel>({});
    private $events = this.$eventSubscriptiob.asObservable();

    get Events() {
        return this.$events;
    }

    RaiseEvent(model: SignalRModel) {
        this.$eventSubscriptiob.next(model);
    }
}

export interface SignalRModel {
    type?: 'ValidateContactEmail' | 'ValidateContactMobilePhone' | 'CustomerInfo' | 'CustomerInfo-Cancel' | 'CreditInfo' | 'CreditInfo-Cancel',
    data?: unknown
}

export interface SignalRTModel<T> extends SignalRModel {
    data?: T
}