import { Injectable, Signal, computed, inject } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { ForgotPasswordResp } from '../../../models/ForgotPassword'
import { UserDetails, UserWelcomeResponse } from '../../../models/Login'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { AddUnauthorizedUserAction, AddUserAction, UserAuthorizedAction, UserState, UserWelcomeInfoAction } from './user.state'

@Injectable({
    providedIn: 'root'
})
export class UserStateService {

    #store: Store = inject(Store)
    #encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
    #environmentService: EnvironmentService = inject(EnvironmentService)

    @Select(UserState.UserInfo) private userSelector$: Observable<string>
    @Select(UserState.userWelcomeInfo) private userWelcomeInfoSelector$: Observable<string>
    @Select(UserState.UnauthorizedUserInfo) private unauthorizedUserSelector$: Observable<string>
    @Select(UserState.isAuthorized) private isLoggedInSelector$: Observable<boolean>

    #userSignal: Signal<string>
    #unauthorizedUserSignal: Signal<string>
    #isLoggedInSignal: Signal<boolean>
    #userWelcomeInfoSignal: Signal<string>

    constructor() {
        this.#userSignal = toSignal(this.userSelector$) as Signal<string>
        this.#userWelcomeInfoSignal = toSignal(this.userWelcomeInfoSelector$) as Signal<string>
        this.#unauthorizedUserSignal = toSignal(this.unauthorizedUserSelector$) as Signal<string>
        this.#isLoggedInSignal = toSignal(this.isLoggedInSelector$) as Signal<boolean>
    }

    setUser(user: UserDetails) {
        this.#store.dispatch(new AddUserAction(user))
    }

    setUnauthorizedUser(user: ForgotPasswordResp) {
        this.#store.dispatch(new AddUnauthorizedUserAction(user))
    }

    setAuthorized(isAuthorized: boolean) {
        this.#store.dispatch(new UserAuthorizedAction(isAuthorized))
    }

    setUserWelcome(response: UserWelcomeResponse) {
        this.#store.dispatch(new UserWelcomeInfoAction(response))
    }

    getUser = computed(() => {
        return this.#encryptDecryptService.decrypt<UserDetails>(this.#userSignal(), this.#environmentService.sessionDecryptKey)
    })

    getUnauthorizedUser = computed(() => {
        return this.#encryptDecryptService.decrypt<ForgotPasswordResp>(this.#unauthorizedUserSignal(), this.#environmentService.sessionDecryptKey)
    })

    getUserWelcomeInfo = computed(() => {
        return this.#encryptDecryptService.decrypt<UserWelcomeResponse>(this.#userWelcomeInfoSignal(), this.#environmentService.sessionDecryptKey)
    })

    getIsLoggedIn = computed(() => this.#isLoggedInSignal())

    reset() {
        this.setUser(null);
        this.setAuthorized(false);
        this.setUserWelcome(null);
      }
}