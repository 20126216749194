import { AfterViewInit, Component, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SignalRHubService } from '../../../services/signalR.hub.service';
import { SessionTimeoutComponent } from '../../../components/sesion-timeout/sesion-timeout.component';
import { AppEventService, EVENT_TYPE } from '../../../services/app-event.service';
import { AuthService } from '../../../services/auth.service';
import { take } from 'rxjs';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrl: './authorized-layout.component.scss'
})
export class AuthorizedLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  #eventService: AppEventService = inject(AppEventService)
  #authService: AuthService = inject(AuthService)
  #signalRHubService = inject(SignalRHubService)
  #environmentService = inject(EnvironmentService)
  logoUrl: string = ''
  @Input() menuItems: MenuItem[]
  @Input() isLoggedIn: boolean;
  @Input() isMenuCollapsed = true;
  @ViewChild('sessionTimeout') sessionTimeout: SessionTimeoutComponent;
  environment = this.#environmentService.env;
  ngOnInit(): void {
    this.#signalRHubService.startConnection().subscribe();
  }

  ngOnDestroy(): void {
    this.#signalRHubService.closeConnection().finally();
  }

  ngAfterViewInit(): void {
    if (this.sessionTimeout) {
      this.sessionTimeout.startWatching();
    }
  }

  onSessionExpired() {
    this.#eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }

  onSessionresume() {
    this.#authService.fetchToken().pipe(take(1)).subscribe();
  }
}
