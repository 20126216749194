import { Injectable, inject } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { PreOrderDetails, StartOrderDetails } from '../../../models/OrderDetails'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { AvailableServices } from '../../../models/AvailableServices'

export const ORDER_STATE_KEY = 'OrderState'
export const ORDER_ACTION_TYPE = {
  SET_PRE_ORDER: `${ORDER_STATE_KEY}.SET_PRE_ORDER`,
  SET_START_ORDER: `${ORDER_STATE_KEY}.SET_START_ORDER`,
  SET_AVAILABLE_SERVICES: `${ORDER_STATE_KEY}.SET_AVAILABLE_SERVICES`,
  SET_FORMS: `${ORDER_STATE_KEY}.SET_FORMS`,
  SET_PRODUCT_DETAILS: `${ORDER_STATE_KEY}.SET_PRODUCT_DETAILS`,
  SET_CREDIT_CHECK_DETAILS: `${ORDER_STATE_KEY}.SET_CREDIT_CHECK_DETAILS`
}

export interface OrderStateModel {
  preOrder?: unknown
  startOrder?: unknown
  availableServices?: unknown
  forms?: unknown
  productDetails?: unknown
  creditCheckDetails?: unknown
}

export class AddPreOrderAction {
  static readonly type = ORDER_ACTION_TYPE.SET_PRE_ORDER
  constructor(public preOrderDetails: PreOrderDetails) { }
}

export class AddStartOrderAction {
  static readonly type = ORDER_ACTION_TYPE.SET_START_ORDER
  constructor(public startOrderDetails: StartOrderDetails) { }
}

export class AddAvailableServicesAction {
  static readonly type = ORDER_ACTION_TYPE.SET_AVAILABLE_SERVICES
  constructor(public availableServices: AvailableServices) { }
}

export class UpdateCustomerInfoAction {
  static readonly type = '[Order] Update Customer Info'
  constructor(public payload: Partial<any>, public currentServices: string) { }
}

export class AddFormsAction {
  static readonly type = ORDER_ACTION_TYPE.SET_FORMS
  constructor(public forms: any) { }
}

export class AddProductDetailsAction {
  static readonly type = ORDER_ACTION_TYPE.SET_PRODUCT_DETAILS
  constructor(public productDetails: any) { }
}

export class AddCreditCheckDetailsAction {
  static readonly type = ORDER_ACTION_TYPE.SET_CREDIT_CHECK_DETAILS
  constructor(public creditCheckDetails: any) { }
}

@State<OrderStateModel>({
  name: ORDER_STATE_KEY,
  defaults: {
    preOrder: undefined,
    startOrder: undefined,
    availableServices: undefined,
    forms: undefined,
    productDetails: undefined,
    creditCheckDetails: undefined
  }
})
@Injectable()
export class OrderState {

  encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
  environmentService: EnvironmentService = inject(EnvironmentService)

  @Action(AddPreOrderAction)
  addPreOrder({ patchState }: StateContext<OrderStateModel>, { preOrderDetails }: AddPreOrderAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(preOrderDetails, this.environmentService.sessionDecryptKey)
    patchState({ preOrder: encryptedUserInfo })
  }

  @Action(AddStartOrderAction)
  addStartOrder({ patchState }: StateContext<OrderStateModel>, { startOrderDetails }: AddStartOrderAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(startOrderDetails, this.environmentService.sessionDecryptKey)
    patchState({ startOrder: encryptedUserInfo })
  }

  @Action(AddAvailableServicesAction)
  addAvailableServices({ patchState }: StateContext<OrderStateModel>, { availableServices }: AddAvailableServicesAction): void {
    const encryptedUserInfo = this.encryptDecryptService.encrypt(availableServices, this.environmentService.sessionDecryptKey)
    patchState({ availableServices: encryptedUserInfo })
  }

  @Action(AddFormsAction)
  addForms({ patchState }: StateContext<OrderStateModel>, { forms }: AddFormsAction): void {
    const encryptedForms = this.encryptDecryptService.encrypt(forms, this.environmentService.sessionDecryptKey)
    patchState({ forms: encryptedForms })
  }

  @Action(AddProductDetailsAction)
  addProductDetails({ patchState }: StateContext<OrderStateModel>, { productDetails }: AddProductDetailsAction): void {
    const encryptedProductDetails = this.encryptDecryptService.encrypt(productDetails, this.environmentService.sessionDecryptKey)
    patchState({ productDetails: encryptedProductDetails })
  }

  @Action(AddCreditCheckDetailsAction)
  addCreditCheckDetails({ patchState }: StateContext<OrderStateModel>, { creditCheckDetails }: AddCreditCheckDetailsAction): void {
    const encryptedCreditCheckDetails = this.encryptDecryptService.encrypt(creditCheckDetails, this.environmentService.sessionDecryptKey)
    patchState({ creditCheckDetails: encryptedCreditCheckDetails })
  }

  @Action(UpdateCustomerInfoAction)
  updateCustomerInfo(ctx: StateContext<OrderStateModel>, action: UpdateCustomerInfoAction) {
    const currentServices = this.encryptDecryptService.decrypt<AvailableServices>(
      action.currentServices,
      this.environmentService.sessionDecryptKey
    )

    if (currentServices) {
      const updatedServices = {
        ...currentServices,
        Customer: {
          ...currentServices.Customer,
          ...action.payload
        }
      };

      const encryptedServices = this.encryptDecryptService.encrypt(
        updatedServices,
        this.environmentService.sessionDecryptKey
      );

      ctx.patchState({
        availableServices: encryptedServices
      });
    }
  }

  @Selector([OrderState])
  static orderInfo(state: OrderStateModel) {
    return state
  }

  @Selector([OrderState])
  static preOrderInfo(state: OrderStateModel) {
    return state?.preOrder
  }

  @Selector([OrderState])
  static startOrderInfo(state: OrderStateModel) {
    return state?.startOrder
  }

  @Selector([OrderState])
  static availableServices(state: OrderStateModel) {
    return state?.availableServices
  }

  @Selector([OrderState])
  static forms(state: OrderStateModel) {
    return state?.forms
  }

  @Selector([OrderState])
  static productDetails(state: OrderStateModel) {
    return state?.productDetails
  }

  @Selector([OrderState])
  static creditCheckDetails(state: OrderStateModel) {
    return state?.creditCheckDetails
  }
}
