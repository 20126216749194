<div class="session-timeout-container">
    <p-dialog header="Session Timeout" [modal]="true" [(visible)]="visible"
        [breakpoints]="{ '2400px': '50vw','1024px': '70vw','575px': '90vw' }">
        <ng-template pTemplate="header">
            <div class="flex flex-1 align-items-center justify-content-center session-header">Session Timeout</div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="pt-5 flex flex-column justify-content-center align-items-center gap-5">
                <div>
                    <span class="session-expire-title">Your session will expire in.</span>
                </div>
                <div>
                    <app-session-timer #timer [startDate]="this.startDate" [endDate]="this.endDate">
                        <ng-template pTemplate="template">
                            <span class="session-timer w-8rem flex gap-3 justify-content-center">
                                @if(timer.timeRemaining.minutes){
                                <span>
                                    {{ timer.timeRemaining.minutes }}m
                                </span>
                                }

                                @if(timer.timeRemaining.seconds){
                                <span>
                                    {{timer.timeRemaining.seconds }}s
                                </span>
                                }
                            </span>
                        </ng-template>
                    </app-session-timer>

                </div>
                <div>
                    <span class="session-note">
                        For your security, your session will end due to inactivity. To keep working, simply interact with the page or click below to stay signed in before the timer expires.
                    </span>
                </div>
                <div class="w-full flex justify-content-end">
                    <p-button label="Continue" (onClick)="visible = false" styleClass="p-button-rounded p-button-lg" />
                </div>
            </div>
        </ng-template>
    </p-dialog>
</div>