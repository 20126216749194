<div class="flex flex-column header bg-color">
    <div class="flex  flex-column">
        <div
            class="flex header justify-content-between align-items-center px-3 py-2 border-black-alpha-10 border-bottom-1 w-full">
            <div class="border-round p-2">
                <a class="flex cursor-pointer logo" routerLink="/welcome">
                    <img src="assets/images/logo/saraplus_logo_menu.png" alt="Sara plus" />
                </a>
            </div>
            <div class="flex flex-row">
                <div class="mr-3">
                    <app-app-profile-icon></app-app-profile-icon>
                </div>
                
                <ng-container *ngIf="isLoggedIn">
                    <a pstyleclass="@next" tabindex="0" enterclass="hidden" leavetoclass="hidden"
                        class="cursor-pointer text-white" (click)="mobilemenu.toggle($event)"
                        (keyup)="mobilemenu.toggle($event)">
                        <i class="pi pi-bars text-4xl"></i></a>
                    <p-overlayPanel #mobilemenu [appendTo]="mobilemenucontainer"
                        styleClass=" absolute w-full surface-800 left-0 border-noround px-2 mt-2 z-2 shadow-2">
                        <p-panelMenu [model]="items" [multiple]="false" styleClass="surface-800">
                        </p-panelMenu>
                        <p-divider></p-divider>
                        <div class="mt-2 flex">                           
                            <p-button icon="pi pi-power-off" label="Sign Out" [link]="true" size="small"
                                (onClick)="onLogoutClicked()" styleClass="p-button-lg font-bold text-white"></p-button>
                        </div>
                    </p-overlayPanel>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="flex flex-column sub-header bg-color text-white font-bold">
        <div
            class="flex header justify-content-between align-items-center px-3 py-2 border-black-alpha-10 border-bottom-1 w-full">
            <div class="border-round p-2 pt-0">
                <app-page-title></app-page-title>
            </div>
            <div class="flex flex-row pl-3">
                <app-session-timer (clicked)="onSessionresume()"></app-session-timer>
            </div>
        </div>
    </div>
    <div #mobilemenucontainer class="absolute w-full" [style]="{'top':'15px !important'}"></div>
</div>