<div>
    <p-button (onClick)="op.toggle($event)" icon="pi pi-user-circle" styleClass="text-white p-button-lg"
        [rounded]="true" [text]="true">
    </p-button>
</div>
<p-overlayPanel #op styleClass="user-profile-container border-1 border-round-lg" (onShow)="onUserMenuShown()"
        (onHide)="onUserMenuHide()">
        <ng-template pTemplate="content">
            <div class="flex flex-column user-overlay-container">
                <div>
                    <ul class="list-none p-0 m-0 pl-3 pr-3 pt-3">
                        <li class="flex align-items-center gap-2">
                            <span class="font-bold">{{userDetails.FirstName}}</span>
                            <span class="font-bold" *ngIf="userDetails.LastName">{{userDetails.LastName}}</span>
                        </li>
                        <li class="flex align-items-center gap-2">
                            <span>{{ userDetails.LoginEmail }}</span>
                        </li>
                        <li class="flex align-items-center gap-2 border-top-1 mt-2 pt-3">
                            <span class="font-bold">IP Address : </span> <span>{{ userDetails.DeviceIP }}</span>
                        </li>
                        <li class="flex align-items-center gap-2" *ngIf="deviceInfo">
                            <span class="font-bold">Location: </span>
                            <span>Des Moines, IA</span>
                        </li>
                        <li class="flex align-items-center gap-2" *ngIf="deviceInfo">
                            <span class="font-bold">Device Info: </span>
                            <span>{{ deviceInfo.deviceType }}, {{deviceInfo.platform}},
                                {{deviceInfo.browserName}}</span>
                        </li>
                        <li class="flex align-items-center gap-2 mb-2">
                            <span class="font-bold">App Version: </span>
                            <span>{{ appVersion }}</span>
                        </li>
                        <li class="border-top-1 p-0">
                            <p-button icon="pi pi-bell" label="Notifications"
                                styleClass="min-w-full p-button-lg profile-button" [text]="true"></p-button>
                        </li>
                        <li class="border-top-1 p-0">
                            <p-button icon="pi pi-power-off" label="Logout"
                                styleClass="min-w-full p-button-danger p-button-lg  profile-button" [text]="true"
                                (onClick)="onLogoutClicked()"></p-button>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>