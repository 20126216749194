import { AfterViewInit, Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { AppBaseModule } from '../../modules/app-base.module';
import { SaraPlusFormModule } from '@sp-forms/saraplus-ngx-forms';
import { PrimeNGModule } from '../../modules/primeng.module';
import { Idle, DocumentInterruptSource, EventTargetInterruptOptions } from '@ng-idle/core';
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SessionTimerModule } from '../session-timer/session-timer.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sesion-timeout',
  templateUrl: './sesion-timeout.component.html',
  styleUrl: './sesion-timeout.component.scss'
})
export class SessionTimeoutComponent implements OnInit, AfterViewInit, OnDestroy {


  #userIdleService = inject(Idle);
  #keepalive = inject(Keepalive);
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  timer: number = 60;
  visible: boolean = false;

  startDate = new Date();
  endDate: Date = new Date();

  @Input() setting: SessionSetting = { idle: 1200, timeout: 60, ping: 60 }
  @Output() ContinueClicked?: EventEmitter<void> = new EventEmitter();
  @Output() SessionExpired?: EventEmitter<void> = new EventEmitter();
  subscriptions = new Subscription()

  ngOnInit(): void {

    // sets an idle timeout of 5 seconds, for testing purposes.
    this.#userIdleService.setIdle(this.setting.idle);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.#userIdleService.setTimeout(this.setting.timeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.#userIdleService.setInterrupts(this.createCustomInterruptSources(null));



    // sets the ping interval to 15 seconds
    //this.#keepalive.interval(this.setting.ping);

    // this.#keepalive.onPing.subscribe(() => {
    //   if (this.SessionExpired) {
    //     this.ContinueClicked.emit();
    //   }
    // });

  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.#userIdleService.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
      if (this.SessionExpired) {
        this.ContinueClicked.emit();
      }
      this.visible = false;
    }));

    this.subscriptions.add(this.#userIdleService.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      setTimeout(() => {
        if (this.SessionExpired) {
          this.SessionExpired.emit();
        }
        this.visible = false;
      }, 1000);
    }));

    this.subscriptions.add(this.#userIdleService.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      this.endDate.setTime((new Date()).getTime() + ((this.setting.timeout + 2) * 1000));
      this.visible = true;
    }));

    this.subscriptions.add(this.#userIdleService.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      this.timer = countdown;
    }));

    this.subscriptions.add(this.#userIdleService.onInterrupt.subscribe(() => {
      if (this.SessionExpired) {
        this.ContinueClicked.emit();
      }
      this.visible = false;
    }));
  }

  reset() {
    this.#userIdleService.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }


  stopWatching() {
    //this.#userIdleService.stopWatching();
  }

  startWatching() {
    this.#userIdleService.watch()
  }

  restart() {
    //this.#userIdleService.resetTimer();
  }

  createCustomInterruptSources(options: EventTargetInterruptOptions) {
    return [
      new DocumentInterruptSource('keydown mouseup touchstart touchmove scroll', options)
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface SessionSetting {
  idle: number,
  timeout: number,
  ping?: number
}

@NgModule({
  declarations: [
    SessionTimeoutComponent
  ],
  imports: [
    AppBaseModule,
    SaraPlusFormModule,
    PrimeNGModule,
    NgIdleKeepaliveModule.forRoot(),
    SessionTimerModule
  ],
  exports: [
    SessionTimeoutComponent
  ]
})
export class SessionTimeoutModule {

}
