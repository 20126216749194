import { Component, inject, OnInit, computed } from '@angular/core';
import { TitleModel } from '../../../services/title.service';
import { BaseComponent } from '../../../components/base.component';
import { OrderStateService } from '../../../store/state/order/order.state.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.scss'
})
export class PageTitleComponent extends BaseComponent implements OnInit {

  title: TitleModel
  orderStateService = inject(OrderStateService)
  orderOptionsPatchedForm = this.orderStateService.getForms
  salesPatchedForm = this.orderStateService.getForms
  preOrderDetails = this.orderStateService.getPreOrder
  startOrderDetails = this.orderStateService.getStartOrder
  orderType = computed(() => this.preOrderDetails()?.OrderType || '')
  interactionType = computed(() => this.preOrderDetails()?.InteractionType || '')
  location = computed(() => this.orderOptionsPatchedForm()?.orderOptionsForm?.location || '')
  campaignName = computed(() => this.startOrderDetails()?.Campaigns?.find(c => c.Id === this.salesPatchedForm()?.salesInformationForm?.campaign)?.Campaign || '')
  accountName = computed(() => this.startOrderDetails()?.Accounts?.find(a => a.DealerCode === this.salesPatchedForm()?.salesInformationForm?.account)?.Store || '')
  retailerName = computed(() => this.startOrderDetails()?.Retailers?.find(r => r.StoreName === this.salesPatchedForm()?.salesInformationForm?.retailer)?.StoreName || '')
  storeName = computed(() => this.startOrderDetails()?.Stores?.find(s => s.DealerCode === this.salesPatchedForm()?.salesInformationForm?.store)?.Store || '')
  salesPersonName = computed(() => this.startOrderDetails()?.SalesPersons?.find(s => s.UserId === this.salesPatchedForm()?.salesInformationForm?.salesPerson)?.FullName || '')

  ngOnInit(): void {
    this.titleService.listen().subscribe({
      next: (model) => {
        this.onTitleChange(model);
      }
    })
  }

  onTitleChange(model: TitleModel) {
    this.title = model;
  }

}
