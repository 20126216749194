import { AfterViewInit, Component, OnInit, ViewChild, inject } from '@angular/core'
import { UserDetails } from '../../../models/Login'
import { BaseComponent } from '../../../components/base.component';
import { AppEventService } from '../../../services/app-event.service';
import { UserStateService } from '../../../store/state/user/user.state.service';
import { AppStateService } from '../../../store/state/app/app.state.service';
import { Token } from '../../../models/Gateway';
import { AuthService } from '../../../services/auth.service';
import { take } from 'rxjs';
import { SessionTimerComponent } from '../../../components/session-timer/session-timer.component';
import { EnvironmentService } from '../../../services/environment.service';
@Component({
  selector: 'app-profile',
  templateUrl: './app-profile.component.html',
  styleUrl: './app-profile.component.scss'
})
export class AppProfileComponent extends BaseComponent implements OnInit, AfterViewInit {
  eventService: AppEventService = inject(AppEventService)
  #userStateService = inject(UserStateService)
  #appStateService = inject(AppStateService)
  #authService: AuthService = inject(AuthService)
  #environmentService: EnvironmentService = inject(EnvironmentService)

  @ViewChild(SessionTimerComponent) sessionTimer: SessionTimerComponent;

  userDetails: UserDetails;
  tokenInfo: Token;
  constructor() {
    super();
  }
  ngAfterViewInit(): void {
    this.subscriptions.add(this.#appStateService.tokenSelector$.subscribe({
      next: () => {
        this.tokenInfo = this.#appStateService.getToken();
        if (this.tokenInfo) {
          this.sessionTimer.start(this.tokenInfo.startDate, this.tokenInfo.endDate);
        }
      }
    }));
  }
  ngOnInit(): void {
    this.userDetails = this.#userStateService.getUser();

  }

  onSessionresume() {
    this.#authService.fetchToken().pipe(take(1)).subscribe();
  }

  get appVersion() {
    return this.#environmentService.env.appVersion;
  }
}
