import { Injectable, inject } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { EnvironmentService } from './environment.service'

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  #environmentService: EnvironmentService = inject(EnvironmentService)

  decrypt<T>(data: string, key: string): T {
    if (!data) return undefined as T

    if (this.#environmentService.getIgnoreEncryption()) {
      try {
        // Try parsing as JSON first
        return JSON.parse(typeof data === 'string' ? data : JSON.stringify(data)) as T;
      } catch {
        // If JSON parsing fails, return the string value directly
        return data as unknown as T;
      }
    }

    const _key = CryptoJS.enc.Utf8.parse(key)
    const _iv = CryptoJS.enc.Utf8.parse('')

    const decrypted = CryptoJS.AES.decrypt(data!, _key, {
      keySize: 32,
      iv: _iv,
    }).toString(CryptoJS.enc.Utf8)
    try {
      // Try parsing as JSON
      return JSON.parse(decrypted) as T;
    } catch {
      // If JSON parsing fails, return the decrypted string directly
      return decrypted as unknown as T;
    }
  }

  encrypt(data: unknown, key: string): string {
    if (!data) return ''

    if (this.#environmentService.getIgnoreEncryption()) {
      return data as string
    }

    const _key = CryptoJS.enc.Utf8.parse(key)
    const _iv = CryptoJS.enc.Utf8.parse('')
    return CryptoJS.AES.encrypt(JSON.stringify(data), _key, {
      keySize: 32,
      iv: _iv
    }).toString()
  }
}
