import { Injectable, Signal, computed, inject } from '@angular/core'
import { Store } from '@ngxs/store'
import { EnvironmentService } from '../../services/environment.service'
import { EncryptDecryptService } from '../../services/encrypt-decrypt.service'
import { AddStateItemAction, RemoveAllItemsAction, RemoveStateItemAction, ResetStateAction } from './generic.state'
import { toSignal } from '@angular/core/rxjs-interop'

@Injectable({
    providedIn: 'root'
})
export class GenericStateService {
    private store = inject(Store)
    private encryptDecryptService = inject(EncryptDecryptService)
    private environmentService = inject(EnvironmentService)

    private items$ = toSignal(
        this.store.select(state => state.GenericState.items)
    )

    private appStateItems$ = toSignal(
        this.store.select(state => state.GenericState.AppStateItems)
    )

    setItem(key: string, value: any) {
        this.store.dispatch(new AddStateItemAction(key, value));
    }

    removeItem(key: string) {
        this.store.dispatch(new RemoveStateItemAction(key));
    }

    removeAllItems() {
        this.store.dispatch(new RemoveAllItemsAction());
    }

    getData<T>(stateKey: string): Signal<T> {
        return computed(() => {
            const encryptedData = this.store.selectSnapshot(state => state.GenericState[stateKey])
            if (!encryptedData) return null
            return this.encryptDecryptService.decrypt<T>(
                encryptedData,
                this.environmentService.sessionDecryptKey
            )
        })
    }

    getItem<T>(key: string): Signal<T | null> {
        return computed(() => {
            const items = this.appStateItems$();
            if (!items || !items[key]) return null;

            return this.encryptDecryptService.decrypt<T>(
                items[key],
                this.environmentService.sessionDecryptKey
            );
        });
    }

    getAllItems<T>(): Signal<{ [key: string]: T }> {
        return computed(() => {
            const items = this.appStateItems$();
            if (!items) return {};

            const result: { [key: string]: T } = {};
            Object.entries(items).forEach(([key, encryptedValue]) => {
                result[key] = this.encryptDecryptService.decrypt<T>(
                    encryptedValue as string,
                    this.environmentService.sessionDecryptKey
                );
            });

            return result;
        });
    }

    reset() {
        this.store.dispatch(new ResetStateAction());
    }
}