<div class="flex justify-content-center align-content-center align-items-center text-white font-bold"
    *ngIf="userDetails">
    <div class="lg:flex md:hidden gap-2 align-content-center justify-content-center align-items-center hidden" *ngIf="userDetails">
        {{userDetails.LoginEmail}}
    </div>
    <p-divider [align]="'left'" type="solid" layout="vertical" styleClass="py-1 border-1 lg:flex md:hidden"></p-divider>
    <div>
        <app-session-timer (clicked)="onSessionresume()"></app-session-timer>
    </div>
    <p-divider [align]="'left'" type="solid" layout="vertical" styleClass="py-1 border-1"></p-divider>
    <div class="flex user-profile-container">
        <app-app-profile-icon></app-app-profile-icon>
    </div>
</div>