<div *ngIf="title" class="text-white font-bold pt-2 hidden md:block">
    <span class="text-xl">{{title.title}}</span>
    <span *ngIf="title.subTitle" class="text-xl">
        - {{title.subTitle}}
    </span>
    @if (title.subTitle) {
    <i class="pi pi-info-circle align-self-center" severity="secondary" tooltipPosition="top"
        (onClick)="orderInfo.toggle($event)" (mouseenter)="orderInfo.toggle($event)">
        <p-overlayPanel #orderInfo styleClass="border-1 border-round-lg" my="top center" at="bottom center">
            <div class="flex flex-column gap-1">
                <div class="flex justify-content-center text-primary p-2 border-round-lg text-base" style="font-weight: 600;">
                    <span>Order Details</span>
                </div>
                <p-divider styleClass="no-margin-divider"></p-divider>
                <div class="flex flex-column gap-2">
                    @if(orderType()) {
                    <div class="align-items-center flex gap-2">
                        <span class="font-bold">Order Type:</span>
                        <span> {{orderType() | titlecase}}</span>
                    </div>
                    }
                    @if(interactionType()) {
                    <div class="align-items-center flex gap-2">
                        <span class="font-bold">Interaction Type:</span>
                        <span> {{interactionType() | titlecase}}</span>
                    </div>
                    }
                    @if(location()) {
                    <div class="align-items-center flex gap-2">
                        <span class="font-bold">Location:</span>
                        <span> {{location() | titlecase}}</span>
                    </div>
                    }
                </div>
                @if(retailerName() || storeName() || campaignName() || accountName()) {
                <div class="flex flex-column gap-2">
                    <p-divider></p-divider>
                    @if (retailerName()) {
                    <div class="align-items-center flex gap-2">
                        <span class="font-bold">Retailer:</span>
                        <span> {{retailerName()}}</span>
                    </div>
                    }
                    @if (storeName()) {
                    <div class="align-items-center flex gap-2">
                        <span class="font-bold">Store:</span>
                        <span> {{storeName()}}</span>
                    </div>
                    }
                    @if (campaignName()) {
                    <div class="align-items-center flex gap-2">
                        <span class="font-bold">Campaign:</span>
                        <span> {{campaignName()}}</span>
                    </div>
                    }
                    @if (accountName()) {
                    <div class="align-items-center flex gap-2">
                        <span class="font-bold">Account:</span>
                        <span>{{accountName()}}</span>
                    </div>
                    }
                </div>
                }
                @if(salesPersonName()) {
                <p-divider></p-divider>
                <div class="align-items-center flex gap-2">
                    <span class="font-bold">Salesperson:</span>
                    <span> {{salesPersonName()}}</span>
                </div>
                }
            </div>
        </p-overlayPanel>
    </i>
    }
</div>

<div *ngIf="title" class="text-white font-bold pt-2 md:hidden">
    <span *ngIf="title.subTitle" class="text-xl">
        {{title.subTitle}}
    </span>
</div>