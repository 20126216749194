import { MenuItem } from 'primeng/api'
import { AfterViewInit, Component, Input, OnInit, ViewChild, inject } from '@angular/core'
import { NavigationEnd } from '@angular/router'
import { filter, take } from 'rxjs'
import { OverlayPanel } from 'primeng/overlaypanel'
import { AppEventService, EVENT_TYPE } from '../../../services/app-event.service'
import { BaseComponent } from '../../../components/base.component'
import { AuthService } from '../../../services/auth.service'
import { SessionTimerComponent } from '../../../components/session-timer/session-timer.component'
import { Token } from '../../../models/Gateway'
import { AppStateService } from '../../../store/state/app/app.state.service'
@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss'
})
export class MobileHeaderComponent extends BaseComponent implements OnInit, AfterViewInit {
  #appStateService = inject(AppStateService)

  #authService = inject(AuthService)
  @Input() items: MenuItem[]
  @ViewChild('mobilemenu') menu: OverlayPanel
  @Input() isLoggedIn: boolean
  #eventService: AppEventService = inject(AppEventService)
  tokenInfo: Token;
  @ViewChild(SessionTimerComponent) sessionTimer: SessionTimerComponent;

  ngOnInit(): void {
    this.subscriptions.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.menu) {
          this.menu.hide()
        }
      }))
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.#appStateService.tokenSelector$.subscribe({
      next: () => {
        this.tokenInfo = this.#appStateService.getToken();
        if (this.tokenInfo) {
          this.sessionTimer.start(this.tokenInfo.startDate, this.tokenInfo.endDate);
        }
      }
    }));
  }

  onSessionresume() {
    this.#authService.fetchToken().pipe(take(1)).subscribe();
  }

  onLogoutClicked(){
    this.#eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }
}
