import { State, Action, StateContext } from '@ngxs/store'
import { EncryptDecryptService } from '../../services/encrypt-decrypt.service';
import { inject } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';

export interface GenericStateModel {
    AppStateItems: { [key: string]: any }
    [key: string]: any;
}

export class SetStateAction {
    static readonly type = '[Generic] Set State';
    constructor(
        public key: string,
        public data: any
    ) { }
}

export class AddStateItemAction {
    static readonly type = '[Generic] Add State Item';
    constructor(
        public key: string,
        public value: any
    ) { }
}

export class RemoveStateItemAction {
    static readonly type = '[Generic] Remove State Item';
    constructor(public key: string) { }
}

export class RemoveAllItemsAction {
    static readonly type = '[Generic] Remove All Items';
}

export class ResetStateAction {
    static readonly type = '[Generic] Reset State';
}

@State<GenericStateModel>({
    name: 'GenericState',
    defaults: {
        AppStateItems: {}
    }
})
export class GenericState {
    encryptDecryptService = inject(EncryptDecryptService)
    environmentService = inject(EnvironmentService)

    @Action(SetStateAction)
    setState(
        { patchState }: StateContext<GenericStateModel>,
        { key, data }: SetStateAction
    ) {
        const encryptedData = this.encryptDecryptService.encrypt(data, this.environmentService.sessionDecryptKey)
        patchState({ [key]: encryptedData });
    }

    @Action(AddStateItemAction)
    addStateItem(
        { getState, patchState }: StateContext<GenericStateModel>,
        { key, value }: AddStateItemAction
    ) {
        const state = getState();
        const encryptedValue = this.encryptDecryptService.encrypt(
            value,
            this.environmentService.sessionDecryptKey
        );

        patchState({
            AppStateItems: {
                ...state.AppStateItems,
                [key]: encryptedValue
            }
        });
    }

    @Action(RemoveStateItemAction)
    removeStateItem(
        { getState, patchState }: StateContext<GenericStateModel>,
        { key }: RemoveStateItemAction
    ) {
        const state = getState();
        const newStateItems = { ...state.AppStateItems };
        delete newStateItems[key];

        patchState({
            AppStateItems: newStateItems
        });
    }

    @Action(RemoveAllItemsAction)
    removeAllItems({ patchState }: StateContext<GenericStateModel>) {
        patchState({
            AppStateItems: {}
        });
    }

    @Action(ResetStateAction)
    resetState({ setState }: StateContext<GenericStateModel>) {
        setState({
            AppStateItems: {}
        });
    }
}
