<div class="flex flex-column justify-content-between min-h-screen layout"
    [ngClass]="{'collapsed-sidebar': isMenuCollapsed, 'expanded-sidebar': !isMenuCollapsed}">
    <div class="relative">
        <div class="flex-1 flex flex-column">
            <app-sidebar [items]="menuItems" [isLoggedIn]="isLoggedIn" class="z-5"></app-sidebar>
            <div name="header">
                <app-sticky-header [logoUrl]="logoUrl" class="hidden md:block"></app-sticky-header>
                <app-mobile-header class="md:hidden" [items]="menuItems" [isLoggedIn]="isLoggedIn"></app-mobile-header>
            </div>
            <div class="flex-1 flex flex-column py-4 px-2 md:py-2 md:px-4 md:mt-6 z-0 main-content main-container">
                <div class="flex-1">
                    <div class="flex flex-column w-full">
                        <div class="w-full flex flex-column authorized-layout">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    </div>
</div>
<app-sesion-timeout #sessionTimeout (SessionExpired)="onSessionExpired()" (ContinueClicked)="onSessionresume()"
    [setting]="{ idle: +environment.sessionIdle, timeout: +environment.sessionTimeout }"></app-sesion-timeout>