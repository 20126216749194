import { DOCUMENT } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { UserStateService } from '../../../../store/state/user/user.state.service';
import { AppStateService } from '../../../../store/state/app/app.state.service';
import { UserDetails } from '../../../../models/Login';
import { Device } from '../../../../models/Device';
import { AppEventService, EVENT_TYPE } from '../../../../services/app-event.service';
import { EnvironmentService } from '../../../../services/environment.service';

@Component({
  selector: 'app-app-profile-icon',
  templateUrl: './app-profile-icon.component.html',
  styleUrl: './app-profile-icon.component.scss'
})
export class AppProfileIconComponent implements OnInit {

  eventService: AppEventService = inject(AppEventService)
  #userStateService = inject(UserStateService)
  #appStateService = inject(AppStateService)
  #environmentService: EnvironmentService = inject(EnvironmentService)

  userDetails: UserDetails;
  deviceInfo: Device;

  #document: Document = inject(DOCUMENT);

  ngOnInit(): void {
    this.userDetails = this.#userStateService.getUser();
    this.deviceInfo = this.#appStateService.getDevice();
  }

  onUserMenuShown() {
    this.#document.body.classList.add('modal-open');
  }
  onUserMenuHide() {
    this.#document.body.classList.remove('modal-open');
  }

  onLogoutClicked() {
    this.eventService.raiseEvent(EVENT_TYPE.LOGOUT)
  }

  get appVersion() {
    return this.#environmentService.env.appVersion;
  }

}
