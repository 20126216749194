import { Injectable, Signal, computed, inject } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service'
import { EnvironmentService } from '../../../services/environment.service'
import { AddAvailableServicesAction, AddCreditCheckDetailsAction, AddFormsAction, AddPreOrderAction, AddProductDetailsAction, AddStartOrderAction, OrderState, UpdateCustomerInfoAction } from './order.state'
import { Observable } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'
import { OrderDetails, PreOrderDetails, StartOrderDetails } from '../../../models/OrderDetails'
import { AvailableServices } from '../../../models/AvailableServices'

@Injectable({
  providedIn: 'root'
})
export class OrderStateService {

  #store: Store = inject(Store)
  #encryptDecryptService: EncryptDecryptService = inject(EncryptDecryptService)
  #environmentService: EnvironmentService = inject(EnvironmentService)

  @Select(OrderState.orderInfo) private orderSelector$: Observable<string>
  @Select(OrderState.preOrderInfo) private preOrderSelector$: Observable<string>
  @Select(OrderState.startOrderInfo) private startOrderSelector$: Observable<string>
  @Select(OrderState.availableServices) private availableServicesSelector$: Observable<string>
  @Select(OrderState.forms) private formsSelector$: Observable<string>
  @Select(OrderState.productDetails) private productDetailsSelector$: Observable<string>
  @Select(OrderState.creditCheckDetails) private creditCheckDetailsSelector$: Observable<string>

  #orderSignal: Signal<string>
  #preOrderSignal: Signal<string>
  #startOrderSignal: Signal<string>
  #availabilityServicesSignal: Signal<string>
  #formsSignal: Signal<string>
  #productDetailsSignal: Signal<string>
  #creditCheckDetailsSignal: Signal<string>

  constructor() {
    this.#orderSignal = toSignal(this.orderSelector$) as Signal<string>
    this.#preOrderSignal = toSignal(this.preOrderSelector$) as Signal<string>
    this.#startOrderSignal = toSignal(this.startOrderSelector$) as Signal<string>
    this.#availabilityServicesSignal = toSignal(this.availableServicesSelector$) as Signal<string>
    this.#formsSignal = toSignal(this.formsSelector$) as Signal<string>
    this.#productDetailsSignal = toSignal(this.productDetailsSelector$) as Signal<string>
    this.#creditCheckDetailsSignal = toSignal(this.creditCheckDetailsSelector$) as Signal<string>
  }

  setPreOrder(preOrder: PreOrderDetails) {
    this.#store.dispatch(new AddPreOrderAction(preOrder))
  }

  setStartOrder(startOrder: StartOrderDetails) {
    this.#store.dispatch(new AddStartOrderAction(startOrder))
  }

  setAvailableServices(availableServices: AvailableServices) {
    this.#store.dispatch(new AddAvailableServicesAction(availableServices))
  }

  setForms(forms: any) {
    this.#store.dispatch(new AddFormsAction(forms))
  }

  setProductDetails(productDetails: any) {
    this.#store.dispatch(new AddProductDetailsAction(productDetails))
  }

  setCreditCheck(creditCheckDetails: any) {
    this.#store.dispatch(new AddCreditCheckDetailsAction(creditCheckDetails))
  }

  updateCustomerInfo(customerUpdates: Partial<any>) {
    const currentServices = this.#availabilityServicesSignal()
    if (currentServices) {
      this.#store.dispatch(new UpdateCustomerInfoAction(customerUpdates, currentServices))
    }
  }

  getPreOrder = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#preOrderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getStartOrder = computed(() => {
    return this.#encryptDecryptService.decrypt<StartOrderDetails>(this.#startOrderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getOrder = computed(() => {
    return this.#encryptDecryptService.decrypt<OrderDetails>(this.#orderSignal(), this.#environmentService.sessionDecryptKey)
  })

  getAvailableServices = computed(() => {
    return this.#encryptDecryptService.decrypt<AvailableServices>(this.#availabilityServicesSignal(), this.#environmentService.sessionDecryptKey)
  })

  getForms = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#formsSignal(), this.#environmentService.sessionDecryptKey)
  })

  getProductDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#productDetailsSignal(), this.#environmentService.sessionDecryptKey)
  })

  getCreditCheckDetails = computed(() => {
    return this.#encryptDecryptService.decrypt<any>(this.#creditCheckDetailsSignal(), this.#environmentService.sessionDecryptKey)
  })

  reset() {
    this.setPreOrder(null)
    this.setStartOrder(null)
    this.setAvailableServices(null)
    this.setProductDetails(null)
    this.setCreditCheck(null)
    this.setForms(null)
  }
}
