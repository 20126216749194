import { Component, OnInit, OnDestroy, Input, NgModule, TemplateRef, AfterContentInit, ContentChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { AppBaseModule } from '../../modules/app-base.module';
import { SaraPlusFormModule } from '@sp-forms/saraplus-ngx-forms';
import { PrimeNGModule } from '../../modules/primeng.module';
import { PrimeTemplate } from 'primeng/api';

@Component({
  selector: 'app-session-timer',
  templateUrl: './session-timer.component.html',
  styleUrl: './session-timer.component.scss'
})
export class SessionTimerComponent implements OnInit, OnDestroy, AfterContentInit {


  @Input() endDate: Date;
  @Input() startDate: Date;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate> | undefined;

  iconTemplate: TemplateRef<any> | undefined;
  timerTemplate: TemplateRef<any> | undefined;
  template: TemplateRef<any> | undefined;

  private dateTick = 0;
  timerSubscription: Subscription;
  timeRemaining: { days: number, hours: number, minutes: number, seconds: number } = {
    days: 0, hours: 0, minutes: 0, seconds: 0
  };

  ngAfterContentInit(): void {
    this.mapTemplates();
  }

  ngOnInit() {
    this.start(this.startDate, this.endDate);
  }

  mapTemplates() {
    if (this.templates) {
      (this.templates as QueryList<PrimeTemplate>).forEach(item => {
        switch (item.getType()) {
          case 'icon':
            this.iconTemplate = item.template;
            break;
          case 'timer':
            this.timerTemplate = item.template;
            break;
          case 'template':
            this.template = item.template;
            break;
        }
      });
    }
  }

  calculateTimeRemaining() {
    const now = (new Date()).getTime();
    const distance = new Date(this.endDate).getTime() - now;

    if (distance < 0) {
      this.timerSubscription.unsubscribe();
      return; // Timer has expired
    }

    this.timeRemaining.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.timeRemaining.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.timeRemaining.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.timeRemaining.seconds = Math.floor((distance % (1000 * 60)) / 1000);
  }

  ngOnDestroy() {
    this.clearInterval();
  }

  clearInterval() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  start(start: Date, end: Date) {
    if (start && end) {
      this.endDate = end;
      this.startDate = start;
      this.clearInterval();
      this.timerSubscription = interval(1000).subscribe(() => {
        this.calculateTimeRemaining();
      });
    }
  }
}

@NgModule({
  declarations: [
    SessionTimerComponent
  ],
  imports: [
    AppBaseModule,
    SaraPlusFormModule,
    PrimeNGModule
  ],
  exports: [
    SessionTimerComponent
  ]
})
export class SessionTimerModule {

}
